import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { getClassNames } from '../util'
import ProductGrid from "../components/store/productGrid"
import contentParser from 'gatsby-wpgraphql-inline-images';

const BassoonHomePage = (props) => {
    const { pageContext } = props
    const { title, content, acfGlobalPages, uri } = pageContext.store
    const { products } = pageContext
    const wpContent = content ? contentParser({ content }, pageContext.plugins.wpImage) : undefined;
    const layoutClasses = getClassNames(acfGlobalPages.sidebarLeft, acfGlobalPages.sidebarRight)

    return (
        <Layout>
            <SEO title={title} description={acfGlobalPages.metaDescription} />
            <div className="container mx-auto p-6 py-16">

                <div className="flex flex-wrap">

                    { layoutClasses.sidebarLeft &&
                    <section className={`${layoutClasses.sidebarLeft} p-3 pt-8`}>
                        <div className="md:border-r p-4">
                            <div dangerouslySetInnerHTML={{__html: acfGlobalPages.sidebarLeft.itemOne}} />
                            <div dangerouslySetInnerHTML={{__html: acfGlobalPages.sidebarLeft.itemTwo}} />
                        </div>
                    </section>
                    }

                    <section className={`${layoutClasses.main} p-3`}>                  
                        <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
                            {title}
                        </h1>
                        <div id="main-content-block">
                            <section className="content-block">{wpContent}</section>
                            <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                                Bassoon Reeds
                            </h2>
                            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                                <ProductGrid 
                                    storeUri={uri} 
                                    products={products} 
                                    filterTags={[]} 
                                />
                            </div>
                    </section>

                    { layoutClasses.sidebarRight &&
                    <section className={`${layoutClasses.sidebarRight} p-3 pt-8`}>
                        <div className="md:border-l p-4">
                            <div className="content-block" dangerouslySetInnerHTML={{__html: acfGlobalPages.sidebarRight.itemOne}} />
                            <div className="content-block" dangerouslySetInnerHTML={{__html: acfGlobalPages.sidebarRight.itemTwo}} />
                        </div>
                    </section>
                    }

                </div>

            </div>
      </Layout>
    )
}

export default BassoonHomePage